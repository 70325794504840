<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="false"
          :showAddNew="false"
          title="PO from CS"
          @onClickAddNewButton="onClickAddNewButton"
          @onClickSettingButton="onClickSettingButton"
      />
      <DateQuerySetter @onClickGo="getAllApprovedCS" :is-disabled="! filterByDate">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <br>
          <br>
          <label class="form-check-label" for="send-mail">Filter by date?</label>
          <input class="form-check-input ms-1"
                 id="send-mail" type="checkbox"
                 v-model="filterByDate"
          />
        </div>
        <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
          <label class="col-form-label" for="cs-number">CS No</label>
          <input id="cs-number" type="text" class="form-control" v-model="csNumber">
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="col-form-label">Supplier</label>
          <vField v-model="supplier_id" class="d-none" name="supplier_id"/>
          <v-select
              placeholder="Select Supplier"
              v-model="supplier_id"
              :options="contacts"
              label="name"
              :reduce="name => name.id"
              @option:selected="getAllApprovedCS()"
          />
          <ErrorMessage
              name="supplier_id"
              class="invalid-feedback d-block qcont"
          />
        </div>
      </DateQuerySetter>
    </div>

    <ListTable
        :cs-list="csList"
        :supplier_id="supplier_id"
    />
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
  </div>
  <GlobalLoader/>
</template>

<script setup>
import TitleButton        from '@/components/atom/TitleButton'
import DateQuerySetter    from '@/components/atom/DateQuerySetter'
import ListTable          from '@/components/molecule/procurement/purchase-order/purchase-order-entry/ApprovedCSQuotationListingTable.vue'
import Pagination         from '@/components/atom/Pagination'
import {
  ref,
  inject,
  onMounted,
  computed,
  watch
}                         from 'vue'
import {
  useRouter,
  useRoute
}                         from 'vue-router'
import handleContact      from '@/services/modules/contact'
import handleRequisitions from "@/services/modules/procurement/requisition";
import {useStore} from "vuex";

const router  = useRouter()
const route   = useRoute()
const store   = useStore()
const loading = ref(false);

const showError              = inject('showError');
const showSuccess            = inject('showSuccess');
const {fetchContactProfiles} = handleContact()
const {fetchApprovedCSList}  = handleRequisitions();
let csList                   = ref({});
let companyId                = computed(() => route.params.companyId);
const offset                 = ref(10);
let page                     = ref(1);
const loader                 = ref(false);
let filterByDate             = ref(false);
let csNumber                 = ref(null);
let contacts                 = ref([])
let supplier_id              = ref(null)

function loadSuppliers() {
  loader.value       = true
  const companyQuery = `?company_id=${companyId.value}`
  const contactRes   = fetchContactProfiles(companyQuery)

  Promise.all([
    contactRes.then(res => {
      if (res.data) contacts.value = res.data
    }),
  ])
      .then(() => {
        loader.value = false;
      })
      .catch((err) => {
        loader.value = false
      })
}

function getQuery() {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += '&start_date=' + route.query.start
  query += '&end_date=' + route.query.end
  if(! filterByDate.value) {
    query += '&without_date_filter=' + 1
  }
  if(csNumber.value && csNumber.value !== '') {
    query += `&cs_number=${csNumber.value}`
  }
  if (supplier_id.value) query += '&supplier_id=' + supplier_id.value
  query += '&page=' + (route.query.page ?? page.value)
  return query
}

function onClickAddNewButton() {
  alert('Add New Button clicked');
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

async function getAllApprovedCS() {
  try {
    csList.value = [];
    const res = await fetchApprovedCSList(getQuery());
    if (res.status) {
      csList.value = res.data;
      setPaginationData({
        records: res.data.total,
        perPage: res.data.per_page,
        currentPage: res.data.current_page,
      })
    } else {
      csList.value = {};
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};

function onPageChange(page) {
  let routeQuery  = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  setTimeout(() => {
    getAllApprovedCS();
  }, 100)
}

onMounted(async () => {
  loadSuppliers();
  await getAllApprovedCS();
})
</script>
