<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="row">
      <div class="col-md-12 text-right mb-2">
        <button :disabled="!selected.length" @click="createPO"
                class="btn btn-primary btn-block waves-effect waves-float waves-light">
          Create PO
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>
            <span v-if="csList.total && supplier_id">
              <input type="checkbox" @click="selectAll" v-model="checkAll">
            </span>
            <span v-else>
              #
            </span>
          </th>
          <th>CS/Quotation No</th>
          <th>CS/Quotation Date</th>
          <th>Supplier</th>
          <th>Requester</th>
          <th>Project</th>
          <th>Department</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in csList.data" :key="i">
          <td>
            <input type="checkbox" v-if="supplier_id"
                   :checked="selected.includes(item.id)"
                   @input="check(item.id)"
            >
          </td>
          <td>{{ item.cs_number }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.contact_profile ? item.contact_profile.full_name: '' }}</td>
          <td>{{ item.cs_master.user?.name }}</td>
          <td>{{ item.cs_master.project?.name }}</td>
          <td>{{ item.cs_master.department?.name }}</td>
          <td>{{ item.status }}</td>
        </tr>
        </tbody>
      </table>
      <p v-if="!csList.total" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {ref}                 from "vue";

const props  = defineProps({
  csList     : Object,
  supplier_id: Number
});
const route  = useRoute();
const router = useRouter();
let selected = ref([]);
let checkAll = ref(false);

function selectAll() {
  selected.value = [];
  if (!checkAll.value) {
    props.csList.data.forEach((item) => {
      selected.value.push(item.id);
    });
  }
}

function check(id) {
  if (selected.value.includes(id)) {
    selected.value.splice(selected.value.indexOf(id), 1);
  } else {
    selected.value.push(id);
  }

  if (selected.value.length === props.csList.data.length) {
    checkAll.value = true;
  } else {
    checkAll.value = false;
  }
}

function createPO() {
  let param = {
    companyId       : route.params.companyId,
    moduleId        : route.params.moduleId,
    menuId          : route.params.menuId,
    pageId          : route.params.pageId,
  }

  let query = route.query;
  query.supplierId = props.supplier_id;
  query.selected_cs_item = selected.value;

  router.push({
    name  : 'po-add',
    params: param,
    query : query
  })
}
</script>
